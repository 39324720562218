<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="37.5" class="progress--header"></ProgressBar>

      <h1 class="page__header">Key Lessons</h1>

      <p class="page__description">
        Please select the categories that you believe will be of the greatest
        importance to your children and the generation after them. We suggest
        that for precision you choose 3-5 categories” Remember, you can create
        more letters later.
      </p>

      <div class="step">
        <p class="step__text">Choose 3-5 categories</p>
        <div class="step__input">
          <div>
            <div class="field" v-for="(lesson, index) in lessons" :key="index">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="checkbox"
                    :id="`checkbox1${index}`"
                    name="lesson"
                    :value="lesson"
                    v-model="selectedLessons[index]"
                    autocomplete="off"
                    required
                    :disabled="disableWhenFull(lessons)"
                  />
                  <label :for="`checkbox1${index}`"><span></span></label>
                </div>
                <label :for="`checkbox1${index}`">{{ lesson }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link
            :to="{ name: 'Greeting' }"
            class="button button--secondary"
            >Go Back</router-link
          >
          <button
            @click="routeToNextPage"
            class="button button--primary"
            :disabled="!validateInput"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/lesson.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'

export default {
  name: 'Lesson',
  components: { ProgressBar },
  data() {
    return {
      selectedLessons: {
        legacy_and_trustees_of_family_history_and_values: '',
        compassion_and_social_impact_and_justice: '',
        encouragement: '',
        harmony_craftsmanship_and_entrepreneurship: '',
        contemplation_meaning_and_transcendence: '',
        breaking_limits_insurgency: '',
        next_gen_grandchildren: '',
        family_celebration_and_togetherness: '',
      },
      lessons: {
        legacy_and_trustees_of_family_history_and_values:
          'Legacy and Trusteeship',
        compassion_and_social_impact_and_justice:
          'Compassion and social justice',
        encouragement: 'Encouragement and support',
        harmony_craftsmanship_and_entrepreneurship:
          'Harmony, craftsmanship and creativity',
        contemplation_meaning_and_transcendence:
          'Contemplation, meaning and transcendence',
        breaking_limits_insurgency: 'Risk taking and breaking limits',
        next_gen_grandchildren: 'Next Generation and grandchildren',
        family_celebration_and_togetherness:
          'Family celebrations and togetherness',
      },
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('lessons')
    if (storedData) {
      this.selectedLessons = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('lessons', this.selectedLessons)
      this.$router.push({ name: 'Images' })
    },
    disableWhenFull(value) {
      return (
        this.selectedLessons.length === 5 &&
        !this.selectedLessons.includes(value)
      )
    },
  },
  computed: {
    validateInput() {
      const lessons = Object.values(this.selectedLessons).filter(
        lesson => lesson
      )
      return lessons.length >= 3 && lessons.length <= 5
    },
  },
}
</script>
